import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Seo } from "../components/seo";
import Layout from "../components/layout";
import HireBanner from "../components/hire/HireBanner";
import OurWorkslef from "../components/hire/OurWorkself";
import CommonServiceComponent from "../components/service/CommonServiceComponent";
import Faq from "../components/Faq";
import { Helmet } from "react-helmet";

const HirePage = () => {
  const banner = {
    title: `Logistics Design Agency`,
    text: "Logistics UI UX Design",
    content: "Our logistics design agency offers top-tier logistics design services to optimize your supply chain and enhance efficiency. We provide customized solutions and innovative strategies tailored to your business needs, ensuring sustainable growth.",
    list: [
      'Data-Driven Approach',
      'Streamlined Process',
      'Custom Logistics Solutions'
    ]
  };
  const ourData = {
    title: `Impacting Businesses With <span class='h1-span'>Logistics Design Services</span>`,
    list1: [
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'Octet has been very supportive to our design and technical requirements. Their designers and developers have always helped us to deliver creative and on-time solutions to our marketing needs.',
        name: 'Mariam Khan',
        des: 'Head Of Digital Marketing'
      },
      {
        logo: ['adani.webp'],
        stars: 5,
        text: "Octet Designers were instrumental in transforming our internal products and processes.",
        name: 'Naveen Kumar',
        des: 'QA Manager'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: "The team's flexibility and understanding of our business needs impressed us with their fast turnaround.",
        name: 'Ishan',
        des: 'Group Product Manager'
      }
    ],
    list2: [
      {
        logo: ['visa.webp'],
        stars: 5,
        text: 'With so many things we could be doing in our product, it was hard to streamline and decide where to invest our time and effort.',
        name: 'Vipin D',
        des: 'Customer Success Manager'
      },
      {
        logo: ['intellect.webp'],
        stars: 4,
        text: 'Using Design first approach with Octet’s designers gave us more clarity and streamlined our solution and delivery.',
        name: 'Todd Baughman',
        des: 'SVP Global Operations'
      },
      {
        logo: ['intellect.webp'],
        stars: 5,
        text: 'They have delivered more than what they have committed. They made our sophisticated platform super simple and classic, our customers love using our app than any competitors.',
        name: 'Parag Chivate',
        des: 'Founder and CEO'
      },
    ]
  };
  const commonContent = {
    title: "Achieve Excellence With Our <span class='h1-span'>Logistics Design Company</span>",
    content: [
      {
        title: "7+ Years of Experience",
        text: "With over 7 years of experience, our logistics design agency brings unparalleled expertise to every project, ensuring seamless operations and optimized solutions.",
      },
      {
        title: "100+ Projects Completed",
        text: "We have successfully completed over 100+ logistics projects, leveraging our skills to deliver result-driven strategy, enhancing efficiency and driving business growth.",
      },
      {
        title: "25% Efficiency Increased",
        text: "We have empowered businesses to streamline their processes and meet the evolving market demands with agility, leading to an increase in efficiency by 25%.",
      },
      {
        title: "Upto 30% Cost Reduction",
        text: "Our logistics design services reduce costs by 30% by leveraging strategic insights and cutting-edge technologies, optimizing resource utilization, and maximizing profit.",
      },
    ],
  };
  const commonContent1 = {
    title: "Boost Your Business With Logistics Design Services",
    content: [
      {
        title: "Industry Expertise",
        text: "Our team has a deep knowledge of the logistics industry and stays up-to-date with the latest trends and technologies, providing valuable insights and guidance to your business.",
      },
      {
        title: "Customized Solution",
        text: "Our flexible approach helps us tailor our designs to meet your specific business requirements and ensure a seamless and efficient logistics experience.",
      },
      {
        title: "Scalable Solutions",
        text: "Our logistics designs can easily be scaled to accommodate your business growth and changing market demands, keeping your logistics agile and responsive.",
      },
      {
        title: "Enhanced Visibility & Tracking",
        text: "We provide real-time visibility and allow you to track shipments, monitor inventory, and make informed decisions, ensuring your operations run smoothly and efficiently.",
      },
      {
        title: "Reduced Operational Costs",
        text: "We optimize our logistics process, apply cost-effective strategies, and help businesses eliminate unnecessary expenses, leading to significant cost savings.",
      },
      {
        title: "Optimized Efficiency",
        text: "We optimize your existing supply chain and identify areas for improvement, which can lead to cost savings, faster delivery times, and improved customer satisfaction.",
      },
    ],
  };
  const Questions = [
    "What is the process for working with your Logistics design agency?",
    "How long does it take to implement your logistics designs?",
    "What kind of support and ongoing assistance do you provide?",
    "Do you have any case studies we can review?"
  ];
  const faqData = [
    {
        para: [
            `We start by conducting a thorough analysis of your current logistics operations and then work closely with you to develop a customized design solution.`,
            `Our process involves collaborative workshops, data gathering, and iterative design refinement to ensure the final solution meets your unique business needs.`
        ]
    },
    {
      para: [
        `The implementation timeline can vary depending on the complexity of your logistics operations and the scope of the project.`,
        'However, our expert logistics designer works diligently to ensure a smooth and timely implementation, minimizing disruptions to your business.'
      ],
    },
    {
      para: [
        `We offer comprehensive support throughout our engagement, including ongoing monitoring, performance optimization, and regular reviews to ensure your logistics operations continue to run smoothly.`,
        `Our team is always available to provide guidance and address any questions or concerns you may have.`
      ]
    },
    {
      para: [
        `Yes, we have a portfolio of successful case studies that demonstrate the positive impact our logistics designs have had on businesses like yours.`,
        `<a href="/contact-us/" title="contact us" target="_blank">Contact us</a>, and we'd be happy to share these examples with you.`
      ],
    }
  ];
  return (
    <>
    <Helmet>
      <script type="application/ld+json">
        {`
            {
                "@context": "https://schema.org",
                "@type": "FAQPage",
                "mainEntity": [{
                "@type": "Question",
                "name": "What is the process for working with your Logistics design agency?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "We start by conducting a thorough analysis of your current logistics operations and then work closely with you to develop a customized design solution. 
            
            Our process involves collaborative workshops, data gathering, and iterative design refinement to ensure the final solution meets your unique business needs."
                }
                },{
                "@type": "Question",
                "name": "How long does it take to implement your logistics designs?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "The implementation timeline can vary depending on the complexity of your logistics operations and the scope of the project. 
            However, our expert logistics designer works diligently to ensure a smooth and timely implementation, minimizing disruptions to your business."
                }
                },{
                "@type": "Question",
                "name": "What kind of support and ongoing assistance do you provide?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "We offer comprehensive support throughout our engagement, including ongoing monitoring, performance optimization, and regular reviews to ensure your logistics operations continue to run smoothly. 
            
            Our team is always available to provide guidance and address any questions or concerns you may have."
                }
                },{
                "@type": "Question",
                "name": "Do you have any case studies we can review?",
                "acceptedAnswer": {
                    "@type": "Answer",
                    "text": "Yes, we have a portfolio of successful case studies that demonstrate the positive impact our logistics designs have had on businesses like yours. 
            
            Connect with us, and we'd be happy to share these examples with you."
                }
                }]
            }                                                      
        `}
      </script>
    </Helmet>
    <Layout hire="Logistics Design Agency" hotjarHire={true} footerText="Maximize your conversions with Us">
      <div>
        <HireBanner data={banner} hire pageName="Hire Conversion Rate Optimisation" />
        <section className="py-[40px]">
          <div className="marquee lg:h-[100px] md:h-[80px] h-[60px]">
            <div className="lg:h-[100px] md:h-[80px] h-[60px]">
              <div className="flex items-center">
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                </div>
              </div>
              <div className="flex items-center">
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[67px]" title="visa" alt="visa" src="../images/visa.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[91px]" title="lode-stone" alt="lode-stone" src="../images/lode-stone.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[69px]" title="adani" alt="adani" src="../images/adani.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[102px]" title="tvs" alt="tvs" src="../images/tvs.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[53px]" title="iima" alt="iima" src="../images/iima.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[116.89px]" title="intellect" alt="intellect" src="../images/intellect.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[122px]" title="aditya-birla" alt="aditya-birla" src="../images/aditya-birla.webp" />
                </div>
                <div className="marquee--logo w-[calc(100vw/3)] lg:w-[calc(100vw/8)]">
                  <StaticImage className="max-w-[100%] h-auto w-[73px]" title="kantime" alt="kantime" src="../images/kantime.webp" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <OurWorkslef data={ourData} hire />
        <CommonServiceComponent data={commonContent} mode={"dark"} hire />
        <CommonServiceComponent data={commonContent1} mode={"light"} hire />
        <Faq grey section Questions={Questions} faqData={faqData} des={''} />
      </div>
    </Layout>
    </>
  );
};

export default HirePage;

export const Head = () => (
  <Seo
    title="Logistics Design Agency | Logistics UI UX Design"
    description="Our logistics design agency offers customized solutions to streamline your supply chain, improve efficiency and reduce costs. Contact us now!"
  />
);
